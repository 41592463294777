import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import {AdaptiveLinkCt} from "@components";

export const AdvantagesSection = () => {

    const {width} = useWindowSize();

    return (
        <AdvantagesSectionContainer>
            <AdvantagesSectionTitleContainer>
                <AdvantagesSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Экономьте время на поиске внештатных
                    специалистов</AdvantagesSectionTitle>
                <AdvantagesSectionDescription swichPoint={LAPTOP_MOBILE_POINTER}>Подберем исполнителя и поможем с
                    документами</AdvantagesSectionDescription>
            </AdvantagesSectionTitleContainer>

            <AdvantagesSectionItems>

                <div style={{'display': 'flex', 'flexWrap': 'wrap', 'gap': '20px', 'width': '756px'}}>

                    <AdvantagesSectionItem style={{
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '368px' : '100%',
                        'height': width && width > LAPTOP_MOBILE_POINTER ? '242px' : 'auto',
                        'background': 'var(--background-second)'
                    }}>
                        <AdvantagesSectionItemInfo
                            style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '90%' : '100%'}}>
                            <TypographyUI.H3 className="title">Официальное сотрудничество</TypographyUI.H3>
                            <TypographyUI.Body1Regular className="description">Все исполнители — самозанятые, которые
                                могут работать с юридическими лицами и ИП</TypographyUI.Body1Regular>
                        </AdvantagesSectionItemInfo>
                    </AdvantagesSectionItem>

                    <AdvantagesSectionItem style={{
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '368px' : '100%',
                        'height': width && width > LAPTOP_MOBILE_POINTER ? '242px' : 'auto',
                        'background': 'var(--background-main-grey)'
                    }}>
                        <AdvantagesSectionItemInfo>
                            <TypographyUI.H3 className="title">Оплата через бухгалтерию</TypographyUI.H3>
                            <TypographyUI.Body1Regular className="description">Платите исполнителю с расчетного счета и
                                не думайте о проблемах со стороны банка и ФНС</TypographyUI.Body1Regular>
                        </AdvantagesSectionItemInfo>
                    </AdvantagesSectionItem>

                    <AdvantagesSectionItem style={{
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '756px' : '100%',
                        'height': width && width > LAPTOP_MOBILE_POINTER ? '278px' : 'auto',
                        'background': 'var(--background-main-grey)',
                        'display': 'flex',
                        'flexDirection': width && width > LAPTOP_MOBILE_POINTER ? 'row' : 'column'
                    }}>
                        <AdvantagesSectionItemInfo style={{
                            'width': width && width > LAPTOP_MOBILE_POINTER ? '306px' : '100%',
                            'flex': width && width > LAPTOP_MOBILE_POINTER ? 'none' : 'unset',
                        }}>
                            <TypographyUI.H3 className="title">Отсутствие налогов и взносов</TypographyUI.H3>
                            <TypographyUI.Body1Regular className="description">Самозанятые уплачивают налог с доходов и
                                страховые взносы самостоятельно. С вас — только гонорар за
                                услугу</TypographyUI.Body1Regular>
                        </AdvantagesSectionItemInfo>

                        <AdvantagesSectionItemImage
                            style={{
                                'height': width && width > LAPTOP_MOBILE_POINTER ? '251px' : 'auto',
                                'width': 'auto',
                                'position': 'relative',
                                'bottom': '20px',
                        }}>
                            <StaticImage style={{'height': '100%'}}
                                src="../../../images/find-selfworkers/AdvantagesSectionImage4.png"
                                loading="eager"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                layout="constrained"
                                objectFit="contain"
                                alt=""
                            />
                        </AdvantagesSectionItemImage>

                    </AdvantagesSectionItem>

                </div>

                <AdvantagesSectionItem style={{
                    'width': width && width > LAPTOP_MOBILE_POINTER ? '364px' : '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '540px' : 'auto',
                    'background': 'var(--background-second)',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'space-between'
                }}>
                    <AdvantagesSectionItemInfo>
                        <TypographyUI.H3 className="title">Полный пакет документов</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Работайте по договору ГПХ, получайте счета,
                            чеки и другие закрывающие документы</TypographyUI.Body1Regular>
                    </AdvantagesSectionItemInfo>
                    <AdvantagesSectionItemImage
                        style={{'width': 'auto', 'bottom': '20px', 'position': 'relative'}}>
                        <StaticImage
                            src="../../../images/find-selfworkers/AdvantagesSectionImage3.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </AdvantagesSectionItemImage>
                </AdvantagesSectionItem>

                <AdvantagesSectionItem style={{
                    'width': '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%',
                    'background': 'var(--background-second)'
                }}>
                    <AdvantagesSectionItemInfo
                        style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '50%' : '100%'}}>
                        <TypographyUI.H3 className="title">Доступ к другим сервисам</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">При подключении к маркетплейсу вы получите доступ к платформе для автоматизации работы с самозанятыми</TypographyUI.Body1Regular>


                        <AdaptiveLinkCt to="https://market.selfwork.ru">
                            <SelfworkTryBtn>
                                Подробнее
                            </SelfworkTryBtn>
                        </AdaptiveLinkCt>


                    </AdvantagesSectionItemInfo>
                    <AdvantagesSectionItemImage style={{
                        'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'none',
                        'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                        'bottom': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 190px)' : '15px',
                        'height': width && width > LAPTOP_MOBILE_POINTER ? '350px' : 'auto',
                        'right': width && width > LAPTOP_MOBILE_POINTER ? '40px' : '0',
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '534px': 'auto',
                    }}>
                        <StaticImage
                            src="../../../images/find-selfworkers/AdvantagesSectionImage5.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </AdvantagesSectionItemImage>
                </AdvantagesSectionItem>

                <AdvantagesSectionItem style={{
                    'width': '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%',
                    'background': 'var(--background-main-grey)',
                }}>
                    <AdvantagesSectionItemInfo
                        style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '48%' : '100%'}}>
                        <TypographyUI.H3 className="title">Простой поиск самозанятого</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Опубликуйте задание и ждите отклики, либо найдите конкретного специалиста с помощью фильтров</TypographyUI.Body1Regular>

                    </AdvantagesSectionItemInfo>
                    <AdvantagesSectionItemImage style={{
                        'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'none',
                        'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                        'bottom': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 186px)' : '15px',
                        'height': width && width > LAPTOP_MOBILE_POINTER ? '371px' : 'auto',
                        'right': width && width > LAPTOP_MOBILE_POINTER ? '40px' : '0',
                        'width': width && width > LAPTOP_MOBILE_POINTER ? '551px' : 'auto'
                    }}>
                        <StaticImage
                            src="../../../images/find-selfworkers/AdvantagesSectionImage6.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </AdvantagesSectionItemImage>
                </AdvantagesSectionItem>

            </AdvantagesSectionItems>

        </AdvantagesSectionContainer>
    )
};

const AdvantagesSectionContainer = styled.section`
    display: flex;
    margin-top: 120px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 20px;
    }
`;

const AdvantagesSectionTitleContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
    max-width: 830px;
    text-align: center;
    @media (max-width: 609px) {
        padding: 0 20px;
    }
`;

const AdvantagesSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;

const AdvantagesSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


const AdvantagesSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const AdvantagesSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const AdvantagesSectionItemInfo = styled.div`
    & .title {
        margin-bottom: 16px;
    }

    & .description {
        color: var(--text-secondary);
    }

    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-bottom: 40px;
    }
`;

const AdvantagesSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;

const SelfworkTryBtn = styled(ButtonUI)`
    width: 240px;
    margin-top: 40px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
