import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const PossibilitiesSection = () => {

    const { width } = useWindowSize();

    return (
        <PossibilitiesSectionContainer>
            <PossibilitiesSectionTitleContainer>
                    <PossibilitiesSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Возможности платформы</PossibilitiesSectionTitle>
            </PossibilitiesSectionTitleContainer>

            <PossibilitiesSectionItems>

                <PossibilitiesSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '555px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '504px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <PossibilitiesSectionItemInfo>
                        <TypographyUI.H3 className="title">Электронный документооборот</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Предоставим все закрывающие документы: договоры, акты выполненных работ и чеки</TypographyUI.Body1Regular>
                    </PossibilitiesSectionItemInfo>
                    <PossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? '0' : '15px', 'left': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 190px)' : '', 'width': width && width > LAPTOP_MOBILE_POINTER ? '380px' : '100%'}}>
                        <StaticImage
                            src="../../../images/find-selfworkers/PossibilitiesSectionImage1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </PossibilitiesSectionItemImage>
                </PossibilitiesSectionItem>

                <PossibilitiesSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '565px' : '100%', 'height' : width && width > LAPTOP_MOBILE_POINTER ? '504px' : '100%', 'background': 'var(--background-second)'}}>
                    <PossibilitiesSectionItemInfo>
                        <TypographyUI.H3 className="title">Иностранные специалисты</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Поможем с оформлением исполнителей из стран ЕАЭС: расскажем, как уведомить МВД и составить договор</TypographyUI.Body1Regular>
                    </PossibilitiesSectionItemInfo>
                    <PossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? '0' : '15px', 'left': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 190px)' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '380px' : '100%'}}>
                        <StaticImage
                            src="../../../images/find-selfworkers/PossibilitiesSectionImage2.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </PossibilitiesSectionItemImage>
                </PossibilitiesSectionItem>

                <PossibilitiesSectionItem style={{'width': '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '470px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <PossibilitiesSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '40%' : '100%'}}>
                        <TypographyUI.H3 className="title">Бесплатные консультации</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Налоговые эксперты и юристы ответят на любые вопросы, связанные с НПД и налогами</TypographyUI.Body1Regular>

                    </PossibilitiesSectionItemInfo>
                    <PossibilitiesSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? 'calc(50% - 190px)' : '15px', 'height': width && width > LAPTOP_MOBILE_POINTER ? '380px' : '100%', 'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '648px' : '100%', 'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'none'}}>
                        <StaticImage
                            src="../../../images/find-selfworkers/PossibilitiesSectionImage3.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </PossibilitiesSectionItemImage>
                </PossibilitiesSectionItem>

            </PossibilitiesSectionItems>

        </PossibilitiesSectionContainer>
    )
};

const PossibilitiesSectionContainer = styled.section`
    display: flex;
    margin-top: 120px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 100px;
    }
`;

const PossibilitiesSectionTitleContainer = styled.div`
    width: 100%;
    @media (max-width: 609px) {
        padding: 0 20px;
    }
`;

const PossibilitiesSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 60px;
`;


const PossibilitiesSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const PossibilitiesSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const PossibilitiesSectionItemInfo = styled.div`
    & .title {
        margin-bottom: 16px;
    }
    & .description {
        color: var(--text-secondary);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-bottom: 40px;
    }
`;

const PossibilitiesSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }
    
`;
