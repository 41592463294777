import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import {AdaptiveLinkCt} from "@components";

export const StartMarketSection = () => {

    const {width} = useWindowSize();

    return (
        <StartMarketSectionContainer>
        <StartMarketSectionItem style={{
            'width': '100%',
            'height': width && width > LAPTOP_MOBILE_POINTER ? '408px' : '100%',
            'background': 'var(--background-second)',
        }}>
            <StartMarketSectionItemInfo
                style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '48%' : '100%'}}>
                <TypographyUI.H2 className="title" swichPoint={LAPTOP_MOBILE_POINTER}>Начните работу с Маркетплейсом — базой самозанятых для вашего бизнеса</TypographyUI.H2>
                <AdaptiveLinkCt to="https://market.selfwork.ru">
                    <SelfworkFindBtn>Найти исполнителя</SelfworkFindBtn>
                </AdaptiveLinkCt>
            </StartMarketSectionItemInfo>

            <StartMarketSectionItemImage style={{
                'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'block',
                'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0',
                'width': width && width > LAPTOP_MOBILE_POINTER ? '646px' : 'auto',
                'top': width && width > LAPTOP_MOBILE_POINTER ? '20px' : '0'
            }}>
                <StaticImage
                    src="../../../images/find-selfworkers/StartMarketSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </StartMarketSectionItemImage>
        </StartMarketSectionItem>
        </StartMarketSectionContainer>
    )
};

const StartMarketSectionContainer = styled.section`
    margin: 0px 0 100px;
    @media (max-width: 609px) {
        margin: 80px -19px 20px;
    }
`;

const StartMarketSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const StartMarketSectionItemInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`;

const StartMarketSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;

const SelfworkFindBtn = styled(ButtonUI)`
    width: 276px;
    margin-top: 20px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        margin-bottom: 40px;
    }
`;
