import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {AdaptiveLinkCt} from "@components";

export const TopSection = () => (
    <TopSectionContainer>
        <TopSectionContent>

            <TopSectionTitle
                swichPoint={LAPTOP_MOBILE_POINTER}
                variant="h1"
            >Найдите своего идеального исполнителя
            </TopSectionTitle>
            <TopSectionDescription
            >Маркетплейс — место, где мы собрали проверенных специалистов из разных областей. Курьер, репетитор, таргетолог — в нашей базе самозанятых найдется каждый
            </TopSectionDescription>

            <AdaptiveLinkCt to="https://market.selfwork.ru">
                <TopSectionBtn>
                    Искать
                </TopSectionBtn>
            </AdaptiveLinkCt>


        </TopSectionContent>

        <TopSectionImage>
            <StaticImage
                src="../../../images/find-selfworkers/TopSectionImage.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={522}
                height={506}
                layout="fixed"
                objectFit="contain"
                alt=""
            />
        </TopSectionImage>

    </TopSectionContainer>
);

const TopSectionContainer = styled.section`
    display: flex;
    margin-top: 37px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const TopSectionContent = styled.div`
    width: 595px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const TopSectionTitle = styled(TypographyUI.H1)`
    span {
        color: var(--accent-main);
    }

    margin-bottom: 16px;
`;

const TopSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    margin-bottom: 48px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`;

const TopSectionImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
    }
`;


const TopSectionBtn = styled(ButtonUI)`
    width: 240px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
